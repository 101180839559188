import ReducerFactory from "../../utils/reducerFactory";
import * as TranslationApi from "./../api/translation";
import toastr from "toastr";

const reducerName = "translation";
const initialState = {
  loading: false,
  loadingCount: false,
  translations: [],
  machineTranslated: 0,
  totalWC: 0,
  professionalTranslated: 0,
  humanVerified: 0,
  newContentAvailable: 0,
  filteredTranslation: [],
  totalFilteredTranslation: 0,
  tmRevisions: [],
  totalTmRevisions: 0,
};

const reducerFactory = new ReducerFactory(reducerName, initialState);

reducerFactory.addAction(
  "TM_LOADING",
  `${reducerName}Loading`,
  (state) => state,
  (state, action) => {
    const newState = Object.assign({}, state);
    newState.loading = action.data;
    return newState;
  }
);
reducerFactory.addAction(
  "TM_COUNTER_LOADING",
  `${reducerName}WordCounterLoading`,
  (state) => state,
  (state, action) => {
    const newState = Object.assign({}, state);
    newState.loadingCount = action.data;
    return newState;
  }
);

reducerFactory.addAction(
  "TM_FETCH",
  `${reducerName}Fetch`,
  async (formValues) => {
    reducerFactory.action(`${reducerName}Loading`, true);
    const response = await TranslationApi.getPaginatedTmByLangAndUrl(
      formValues
    );
    return response;
  },
  (state, action) => {
    const newState = Object.assign({}, state);
    if (action.data.success) {
      newState.translations = action.data.data.tm;
      newState.totalTranslations = action.data.data.total;
    }
    newState.loading = false;
    return newState;
  }
);
reducerFactory.addAction(
  "TM_FETCH",
  "translationSEOFetch",
  async (formValues) => {
    reducerFactory.action(`${reducerName}Loading`, true);
    const response = await TranslationApi.translationSEOFetch(formValues);
    return response;
  },
  (state, action) => {
    const newState = Object.assign({}, state);
    if (action.data.success) {
      newState.translations = action.data.data.tm;
      newState.totalTranslations = action.data.data.total;
    }
    newState.loading = false;
    return newState;
  }
);

reducerFactory.addAction(
  "FETCH_TM_COUNTS",
  "fetchTmCounts",
  async (formValues) => {
    reducerFactory.action(`${reducerName}WordCounterLoading`, true);
    const response = await TranslationApi.fetchTmCounts(formValues);
    return response;
  },
  (state, action) => {
    const newState = Object.assign({}, state);
    if (action.data.success) {
      newState.machineTranslated = action.data.data.machineTranslated;
      newState.totalWC = action.data.data.totalWC;
      newState.professionalTranslated = action.data.data.professionalTranslated;
      newState.humanVerified = action.data.data.humanVerified;
      newState.newContentAvailable = action.data.data.newContentAvailable;
    }
    newState.loadingCount = false;
    return newState;
  }
);

reducerFactory.addAction(
  "NMT_APPLY",
  `applyNmtTranslation`,
  async (formValues) => {
    toastr.info("NMT output will be applied in a moment", "NMT Translation");
    console.log("here -->", formValues);
    const response = await TranslationApi.applyNmtTranslationToTm(formValues);
    return response;
  },
  (state, action) => {
    if (action.data.success) {
      toastr.success("NMT applied successfully", "NMT Translation");
      reducerFactory.action();
    } else {
      toastr.error(action.data.status);
    }
    return state;
  }
);

reducerFactory.addAction(
  "TRANSLITERATION_APPLY",
  `applyTransliteration`,
  async (formValues) => {
    toastr.info(
      "Transliteration output will be applied in a moment",
      "Transliteration"
    );
    const response = await TranslationApi.applyTransliterationToTm(formValues);
    return response;
  },
  (state, action) => {
    if (action.data.success) {
      toastr.success("Transliteration applied successfully", "Transliteration");
      reducerFactory.action();
    } else {
      toastr.error(action.data.status);
    }
    return state;
  }
);

//applyTransliteration

reducerFactory.addAction(
  "USER_TRANSLATION_APPLY",
  `applyUserTranslation`,
  async (formValues) => {
    console.log(formValues);
    toastr.info(
      "User Translation/Transliteration will be updated in a moment",
      "User Translation/Transliteration"
    );
    const response = await TranslationApi.applyUserTranslationToTm(formValues);
    return response;
  },
  (state, action) => {
    console.log(state, action, "--------");
    const newState = Object.assign({}, state);
    console.log(action.data);
    let message =
      action?.data?.data?.displayType === "t13n"
        ? "User Transliteration updated successfully"
        : "User Translation updated successfully";
    let messageHeader =
      action?.data?.data?.displayType === "t13n"
        ? "User Transliteration"
        : "User Translation";
    if (action.data.success) {
      toastr.success(message, messageHeader);
    } else {
      toastr.error(
        action?.data?.success === false
          ? "Error:User Translation update failed"
          : "User Translation update failed",
        messageHeader
      );
    }
    return newState;
  }
);

reducerFactory.addAction(
  "TM_FILTERED",
  "fetchTmByFilter",
  async (filterValues) => {
    reducerFactory.action(`${reducerName}Loading`, true);
    const response = await TranslationApi.fetchTmByFilter(filterValues);
    if (filterValues.download && response.success) {
      toastr.success(
        "The report file will be downloaded shortly. Please check your Downloads folder"
      );
    }
    return response;
  },
  (state, action) => {
    const newState = Object.assign({}, state);
    if (action.data.success) {
      if (!action.data.data.download) {
        newState.filteredTranslation = action.data.data.tmData;
        newState.totalFilteredTranslation = action.data.data.count;
      }
    }
    newState.loading = false;
    return newState;
  }
);

reducerFactory.addAction(
  "DOWNLOAD_REPORT",
  "downloadReport",
  async (filterValues) => {
    reducerFactory.action(`${reducerName}Loading`, true);
    const response = await TranslationApi.downloadReport(filterValues);
    return response;
  },
  (state, action) => {
    const newState = Object.assign({}, state);
    if (action.data.success) {
      newState.filteredTranslation = action.data.data.tmData;
    }
    newState.loading = false;
    return newState;
  }
);

reducerFactory.addAction(
  "ANALYZE_WORD_COUNT",
  "analyzeWordCount",
  async (params) => {
    reducerFactory.action(`${reducerName}Loading`, true);
    const response = await TranslationApi.analyzeWordCount(params);
    return response;
  },
  (state, action) => {
    const newState = Object.assign({}, state);
    if (action.data.success) {
      newState.filteredTranslation = action.data.data.tmData;
    }
    newState.loading = false;
    return newState;
  }
);

reducerFactory.addAction(
  "TOGGLE_DISPLAY_DATA",
  "toggleDisplayData",
  async (params) => {
    const response = await TranslationApi.toggleDisplayData(params);
    return response;
  },
  (state, action) => {
    const newState = Object.assign({}, state);
    if (action.data.success) {
      toastr.success("Display Type Updated Successfully");
    }
    return newState;
  }
);

reducerFactory.addAction(
  "CREATE_TM_REVISION",
  `createTmRevision`,
  async (params) => {
    const response = await TranslationApi.createTmRevision(params);
    return response;
  },
  (state, action) => {
    const newState = Object.assign({}, state);
    if (action.data.success) {
      toastr.success("TM Revision added successfully", "TM Revision");
    } else {
      toastr.error("Unable to create TM Revision ", "TM Revision");
    }
    return newState;
  }
);

reducerFactory.addAction(
  "FETCH_TM_REVISION",
  `fetchTmRevision`,
  async (params) => {
    reducerFactory.action("resetTmRevision", {});
    reducerFactory.action(`${reducerName}Loading`, true);
    const response = await TranslationApi.fetchTmRevision(params);
    return response;
  },
  (state, action) => {
    const newState = Object.assign({}, state);
    if (action?.data?.data?.success) {
      const { tmRevisions, total } = action.data.data.data;
      newState.tmRevisions = tmRevisions;
      newState.totalTmRevisions = total;
    } else {
      toastr.error("Unable to fetch TM Revision ", "TM Revision");
    }
    newState.loading = false;
    return newState;
  }
);

reducerFactory.addAction(
  "RESET_TM_REVISION",
  `resetTmRevision`,
  (params) => {},
  (state, action) => {
    const newState = Object.assign({}, state);
    newState.tmRevisions = [];
    newState.totalTmRevisions = 0;
    return newState;
  }
);

reducerFactory.addAction(
  "GENERATE_REPORT",
  `generateReport`,
  async (params) => {
    reducerFactory.action(`${reducerName}Loading`, true);
    const response = await TranslationApi.generateReport(params);
    return response;
  },
  (state, action) => {
    const newState = Object.assign({}, state);
    return newState;
  }
);

reducerFactory.addAction(
  "FETCH_META_TAGS",
  "fetchMetaTags",
  async (params) => {
    reducerFactory.action(`${reducerName}Loading`, true);
    const response = await TranslationApi.fetchMetaTags(params);
    return response;
  },
  (state, action) => {
    const newState = Object.assign({}, state);
    if (action.data.success) {
      newState.translations = action.data.data.tm;
      newState.totalTranslations = action.data.data.total;
    }
    newState.loading = false;
    return newState;
  }
);

reducerFactory.addAction(
  "DOWNLOAD_BATCH_FILE",
  `downloadBatchFile`,
  async (params) => {
    reducerFactory.action(`${reducerName}Loading`, true);
    const response = await TranslationApi.downloadBatchFile(params);
    return response;
  },
  (state, action) => {
    const newState = Object.assign({}, state);
    return newState;
  }
);
reducerFactory.addAction(
  "MOBILE_TRANSLITERATION",
  `mobileTranslation`,
  async (formData) => {
    const response = await TranslationApi.mobileTranslation(formData);
    return response;
  },
  (state, action) => {
    console.log(action.data.success, "----");

    if (action.data.success) {
      toastr.success("Project Created successfully");
      reducerFactory.action();
    } else {
      toastr.error(action.data.status);
    }
    return state;
  }
);

reducerFactory.addAction(
  "ON_MOBILE_PROJECT_RESYNC",
  `onMobileprojectResync`,
  async (Data) => {
    toastr.info("Please wait project is beeing resync");
    const response = await TranslationApi.onMobileprojectResync(Data);
    return response;
  },
  (state, action) => {
    if (action.data.success) {
      toastr.success("Project resync is sucessfull");
      reducerFactory.action();
    } else {
      toastr.error(action.data.status);
    }
    return state;
  }
);

reducerFactory.addAction(
  "LANGUAGE_DELETION",
  `languageDeletion`,
  async (formData) => {
    const response = await TranslationApi.languageDeletion(formData);
    return response;
  },
  (state, action) => {
    if (action.data.success) {
      toastr.success("Project Created successfully");
      reducerFactory.action();
    } else {
      toastr.error(action.data.status);
    }
    return state;
  }
);

reducerFactory.addAction(
  "GET_STRINGXML_FILE_PATH_BY_LANGUAGE",
  `getStringxmlFilePathByLanguage`,
  async (formData) => {
    const response = await TranslationApi.getStringxmlFilePathByLanguage(
      formData
    );
    return response;
  },
  (state, action) => {
    if (action.data.success) {
      toastr.success(action.data.message);
      reducerFactory.action();
    } else {
      toastr.error(action.data.message);
    }
    return state;
  }
);

reducerFactory.addAction(
  "GET_XML_FILE_URLS",
  `getstringxmlFileurls`,
  async (formData) => {
    const response = await TranslationApi.getstringxmlFileurls(formData);
    return response;
  },
  (state, action) => {
    if (action.data.success) {
      toastr.success("csv file downloded successfully");
      reducerFactory.action();
    } else {
      toastr.error(action.data.status);
    }
    return state;
  }
);

reducerFactory.addAction(
  "REPLACE_TEXT_SEGMENTS",
  "replaceTextSegments",
  async (params) => {
    const response = await TranslationApi.replaceTextSegment(params);
    return response;
  },
  (state, action) => {
    const newState = Object.assign({}, state);
    if (action.data.success) {
      const modifiedTm = action.data.data.modifiedTm;
      console.log(modifiedTm);
      if (modifiedTm === 0) {
        toastr.warning(`No Strings found to replace!`);
      } else {
        toastr.success(`Text replaced in ${modifiedTm} tm successfully`);
      }
    } else {
      toastr.error("Some error occurred while replacing text segments");
    }
    return newState;
  }
);

reducerFactory.addAction(
  "MOBILE_LANGUAGE_ADDITION",
  `mobileLanguageAddition`,
  async (formData) => {
    const response = await TranslationApi.mobileLanguageAddition(formData);
    return response;
  },
  (state, action) => {
    if (action.data.success) {
      toastr.success("Language Added successfully");
      reducerFactory.action();
    } else {
      toastr.error(action.data.status);
    }
    return state;
  }
);

reducerFactory.addAction(
  "VIEW_ANALYSIS_DATA",
  `viewAnalysisData`,
  async (params) => {
    const response = await TranslationApi.viewAnalysisData(params);
    return response;
  },
  (state, action) => {
    if (action.data.success) {
      reducerFactory.action();
    } else {
      toastr.error(action.data.status);
    }
    return state;
  }
);

reducerFactory.addAction(
  "FETCH_SUGGESTION_DATA",
  "fetchSuggestionData",
  async (params) => {
    reducerFactory.action(`${reducerName}Loading`, true);
    const response = await TranslationApi.fetchSuggestionData(params);
    return response;
  },
  (state, action) => {
    const newState = Object.assign({}, state);
    if (action.data.success) {
      const data = action.data.data;
      newState.translations = data.suggestions;
      newState.totalTranslations = data.total;
    } else {
      newState.translations = [];
    }
    newState.loading = false;

    return newState;
  }
);

reducerFactory.addAction(
  "FETCH_FEEDBACK_DATA",
  "fetchFeedbackData",
  async (params) => {
    reducerFactory.action(`${reducerName}Loading`, true);
    const response = await TranslationApi.fetchFeedbackData(params);
    return response;
  },
  (state, action) => {
    const newState = Object.assign({}, state);
    if (action.data.success) {
      const data = action.data.data;
      newState.translations = data.records;
      newState.totalTranslations = data.totalRecords;
    } else {
      newState.translations = [];
    }
    newState.loading = false;

    return newState;
  }
);

reducerFactory.addAction(
  "CLEAR_SUGGESTION_DATA",
  "clearSuggestionData",
  () => {},
  (state, action) => {
    const newState = Object.assign({}, state);
    newState.translations = [];
    return newState;
  }
);

reducerFactory.addAction(
  "ACCEPT_SUGGESTION_DATA",
  "acceptSuggestionData",
  async (params) => {
    reducerFactory.action(`${reducerName}Loading`, true);
    const response = await TranslationApi.acceptSuggestionData(params);
    return response;
  },
  (state, action) => {
    if (action.data.success) {
      toastr.success("Suggestion accepted as TM");
    } else {
      toastr.error(
        "Some error occured while accepting suggestion please update again!!."
      );
    }
    return state;
  }
);

reducerFactory.addAction(
  "REJECT_SUGGESTION_DATA",
  "rejectSuggestionData",
  async (params) => {
    reducerFactory.action(`${reducerName}Loading`, true);
    const response = await TranslationApi.rejectSuggestionData(params);
    return response;
  },
  (state, action) => {
    if (action.data.success) {
      toastr.info("Suggestion rejected.");
    } else {
      toastr.error("Unable to reject suggestion");
    }

    return state;
  }
);

reducerFactory.addAction(
  "DOWNLOAD_REPORT_SUGGESTION",
  "downloadSuggestionReport",
  async (params) => {
    toastr.info(
      "Downloading report. Please wait for your download to complete"
    );
    const response = await TranslationApi.downloadSuggestionReport(params);
    return response;
  },
  (state, action) => {
    const url = window.URL.createObjectURL(new Blob([action.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "suggestionReport.csv");
    document.body.appendChild(link);
    link.click();
    link.remove();
    return state;
  }
);

reducerFactory.addAction(
  "UPLOAD_SUGGESTION_REPORT",
  "uploadSuggestionReport",
  async (params) => {
    toastr.info(
      "Uploading suggestions, Pleas wait for processing to complete!"
    );
    reducerFactory.action(`${reducerName}Loading`, true);
    const response = await TranslationApi.uploadSuggestionReport(params);
    return response;
  },
  (state, action) => {
    const newState = Object.assign({}, state);
    if (action.data[0].success) {
      const { updated, noActionTaken } = action.data[0].data;
      toastr.success("Suggestions processed successfully,");
      toastr.info(`Updated Suggestions: ${updated}`);
      toastr.info(`No Action Taken: ${noActionTaken}`);
    } else {
      toastr.warning(action.data[0].message);
    }
    newState.loading = false;
    return newState;
  }
);

  reducerFactory.addAction("FETCH_FEEDBACK_DATA_FOR_CSV", 'fetchFeedbackDataForCsv',
    async (params) => {
      reducerFactory.action(`${reducerName}Loading`, true);
      toastr.info("Downloading report. Please wait for your download to complete");
      const response = await TranslationApi.fetchFeedbackDataForCsv(params);
      return response;
    },
    (state,action) => {
      const url = window.URL.createObjectURL(new Blob([action.data])) 
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', "feedbackReport.csv")
      document.body.appendChild(link)
      link.click()
      link.remove()
      return state;
    });

// HELPERS
function stylizeSource(source, tagDict, source_language) {
  if (!source_language) {
    source_language = "english";
  }
  let nmtSource = source;
  const htmltags = [];
  const tagMap = {};
  const entities = [];
  let mathentityCount = 0;
  if (tagDict) {
    let imgCount = 1;
    let urlCount = 1;
    Object.keys(tagDict).forEach((tag, i) => {
      entities.push(tag);
      let tagObj;
      if (tagDict[tag].type === "htmltag") {
        tagObj = tagDict[tag];
        tagObj.tag_name = tag;
        htmltags.push(tagObj);
      }
      if (tagDict[tag].type === "imgtag") {
        tagMap[
          tag
        ] = `<span class="tag-mark-entity" data-tag="${tag}" contenteditable="false"><span data-tag="${tag}">IMAGE-${imgCount}</span></span>`;
        source = source.replace(tag, tagMap[tag]);
        nmtSource = nmtSource.replace(tag, tagDict[tag][source_language]);
        imgCount++;
      }
      if (tagDict[tag].type === "notag_angle_bracket") {
        tagMap[
          tag
        ] = `<span class="tag-mark-entity" data-tag="${tag}" contenteditable="false"><span data-tag="${tag}">${
          tagDict[tag][source_language] === "<" ? "\u1438" : "\u1433"
        }</span></span>`;
        source = source.replace(tag, tagMap[tag]);
        nmtSource = nmtSource.replace(tag, tagDict[tag][source_language]);
      }
      if (tagDict[tag].type === "url") {
        tagMap[
          tag
        ] = `<span class="tag-mark-entity" data-tag="${tag}" contenteditable="false"><span data-tag="${tag}">URL-${urlCount}</span></span>`;
        source = source.replace(tag, tagMap[tag]);
        nmtSource = nmtSource.replace(tag, tagDict[tag][source_language]);
        urlCount++;
      }
      if (
        tagDict[tag].type !== "mathentity" &&
        tagDict[tag].type !== "url" &&
        tagDict[tag].type !== "htmltag" &&
        tagDict[tag].type !== "imgtag" &&
        tagDict[tag].type !== "notag_angle_bracket"
      ) {
        tagMap[
          tag
        ] = `<span class="tag-mark-entity" data-tag="${tag}" contenteditable="false"><span data-tag="${tag}">${encodeURI(
          tagDict[tag][source_language]
        )}</span></span>`;
        source = source.replace(tag, tagMap[tag]);
        nmtSource = nmtSource.replace(tag, tagDict[tag][source_language]);
      }
      if (tagDict[tag].type === "mathentity") {
        // eslint-disable-next-line no-plusplus
        mathentityCount++;
        tagMap[
          tag
        ] = `<span class="tag-mark-entity" data-tag="${tag}" contenteditable="false"><span data-tag="${tag}">EXPR-${mathentityCount}</span></span>`;
        source = source.replace(tag, tagMap[tag]);
        nmtSource = nmtSource.replace(tag, tagDict[tag][source_language]);
      }
    });
  }
  htmltags.forEach((tg) => {
    if (tg) {
      // eslint-disable-next-line
      let types = {
        open: /<[a-zA-Z](.*?[^?^\/])?>/gm,
        close: /<\/\w+\>/gm,
        full: /<\w+\s+(id=\"(\w\d+|\d+)\"|)\/\>/gm,
      };
      Object.keys(types).forEach((type) => {
        const regex = types[type];
        if (regex.exec(tg[source_language]) !== null) {
          const numMarker = tg.tag_name.match(/\d+/);
          tagMap[
            tg.tag_name
          ] = `<span class="tag-mark-${type}" data-tag="${tg.tag_name}" contenteditable="false"><span data-tag="${tg.tag_name}">${numMarker}</span></span>`;
          source = source.replace(tg.tag_name, tagMap[tg.tag_name]);
          nmtSource = nmtSource.replace(tg.tag_name, "");
        }
      });
    }
  });
  return { source, nmtSource, tagMap };
}

reducerFactory.addAction(
  "URL_PARSED_DATA",
  "urlParsedData",
  async (params) => {
    const response = await TranslationApi.urlParsedData(params);
    return response;
  },
  (state, action) => {
    if (action.data.success) {
      toastr.info("Suggestion rejected.");
    } else {
      toastr.error("Unable to reject suggestion");
    }

    return state;
  }
);

export default reducerFactory;
