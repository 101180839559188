import * as Notification from "../api/notification.js";
import ReducerFactory from "../../utils/reducerFactory";

const reducerName = "notification";

const initialState = {
  notifications: [],
  loading: false,
};

const reducerFactory = new ReducerFactory(reducerName, initialState);

reducerFactory.addAction(
  "NOTIFICATION_LOADING",
  `${reducerName}Loading`,
  (status) => status,
  (state, action) => {
    const newState = Object.assign({}, state);
    newState.loading = action.data;
    return newState;
  }
);

reducerFactory.addAction(
  "FETCH_NOTIFICATION",
  "fetchNotification",
  async (pageNumber) => {
    reducerFactory.action(`${reducerName}Loading`, true);
    const response = await Notification.fetchNotification(pageNumber);
    return response;
  },
  (state, action) => {
    let newState;
    if (action.data.success) {
      newState = Object.assign({}, state);
      newState.notifications = action.data.data.notifications;
      newState.total = action.data.data.total;
    } else {
      newState = Object.assign({}, state);
    }
    newState.loading = false;
    return newState;
  }
);

reducerFactory.addAction(
  "FETCH_DRAWER_NOTIFICATION",
  "fetchNotificationForDrawer",
  async (pageNumber) => {
    reducerFactory.action(`${reducerName}Loading`, true);
    const response = await Notification.fetchNotification(pageNumber);
    return response;
  },
  (state, action) => {
    let newState;
    if (action.data.success) {
      newState = Object.assign({}, state);
      newState.AllNotifications = action.data.data.notifications;
      newState.total = action.data.data.total;
    } else {
      newState = Object.assign({}, state);
    }
    newState.loading = false;
    return newState;
  }
);

reducerFactory.addAction(
  "SET_NOTIFICATIONS",
  "setNotifications",
  (param) => {
    return param;
  },
  (state, action) => {
    const newState = Object.assign({}, state);
    newState.notifications = action.data.res.data;
    return newState;
  }
);

reducerFactory.addAction(
  "DELETE_NOTIFICATION",
  "deleteNotification",
  (param) => param,
  (state, action) => {
    const newState = Object.assign({}, state);
    newState.notifications = newState.notifications.filter((e) => {
      if (action.data.includes(e.notificationId)) {
        return false;
      }
      return true;
    });
    return newState;
  }
);

reducerFactory.addAction(
  "UPDATE_SEEN_STATUS",
  "updateSeenStatus",
  (param) => param,
  (state, action) => {
    const newState = Object.assign({}, state);
    newState.notifications = newState.notifications.map((e) => {
      if (e.notificationId === action.data.notificationId) {
        e.seen = true;
      }
      return e;
    });
    return newState;
  }
);

reducerFactory.addAction(
  "ADD_NOTIFICATION",
  "addNotification",
  (param) => {
    return param;
  },
  (state, action) => {
    const newState = Object.assign({}, state);
    newState.notifications = [action.data, ...newState.notifications];
    return newState;
  }
);

export default reducerFactory;
