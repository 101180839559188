import React, { Component } from "react";
// import { connect } from "react-redux";
// import authReducer from "../../redux/modules/auth";
import { Layout, Button, Form, Input, Checkbox, Row } from "antd";
import { Link } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import {
  EyeOutlined,
  MailOutlined,
  EyeInvisibleOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
const { Content } = Layout;

class SignInForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      inputType: "password",
      recaptchaValue:""
    };
  }

  togglePasswordField = () => {
    const { inputType } = this.state;
    this.setState({
      inputType: inputType === "password" ? "text" : "password",
    });
  };
  handleRecaptchaChange = (value) => {
    this.setState({ recaptchaValue: value });
  };
  render() {
    const { email, password, inputType ,recaptchaValue } = this.state;
    const { locked, attempts, history } = this.props;

    /**Icon Component to toggle password visibility field */
    const ToggleIcon = () => {
      return (
        <div onClick={this.togglePasswordField} style={{ cursor: "pointer" }}>
          {inputType === "password" ? (
            <>
              <EyeInvisibleOutlined />
            </>
          ) : (
            <>
              <EyeOutlined />
            </>
          )}
        </div>
      );
    };
    return (
      <Content className="sign-container">
        <div className="sign-form">
          <div className="text-center">
            <h2>Sign-In</h2>
          </div>
          {locked && (
            <div
              style={{
                padding: "10px",
                border: "#da8d00 2px solid",
                borderRadius: "9px",
                margin: "9px 0px",
              }}
            >
              <Row>
                <ExclamationCircleOutlined
                  style={{ fontSize: "1.8em", color: "#da8d00" }}
                />
                <span
                  style={{
                    marginLeft: "5px",
                    fontSize: "1.3em",
                    color: "#da8d00",
                  }}
                >
                  {" "}
                  Too many failed attempts
                </span>
              </Row>
              <div>Your account has been temporarily locked</div>
              <div>
                Please try again after <b>30 minutes</b> or{" "}
                <Link
                  to="/forgotPassword"
                // className="text-dark"
                // style={{ color: "#0f3dd4", cursor: "pointer" }}
                >
                  reset your password
                </Link>
              </div>
            </div>
          )}

          <div>
            <Form name="basic" className="row-col">
              <Form.Item
                name="email"
                rules={[
                  { required: true, message: "Please input your email!" },
                ]}
              >
                <Input
                  suffix={<MailOutlined />}
                  placeholder="Enter your email"
                  onChange={(e) => this.setState({ email: e.target.value })}
                />
              </Form.Item>
              <Form.Item
                name="password"
                type="password"
                rules={[
                  { required: true, message: "Please input your password!" },
                ]}
              >
                <Input
                  suffix={<ToggleIcon />}
                  placeholder="Enter your password"
                  onChange={(e) => this.setState({ password: e.target.value })}
                  type={inputType}
                />
              </Form.Item>

              {/* <Form.Item name="remember" valuePropName="checked">
                  <Checkbox>
                    I agree the{" "}
                    <a href="#pablo" className="font-bold text-dark">
                      Terms and Conditions
                    </a>
                  </Checkbox>
                </Form.Item> */}
              {/* <Form.Item>
               <ReCAPTCHA
                    sitekey="6LeXAZsoAAAAAMelpacwTmvev8SXHfn83E6PjyFy"
                    onChange={this.handleRecaptchaChange}
              />
              </Form.Item> */}

              <Form.Item>
                <Button
                  style={{ width: "100%" }}
                  type="primary"
                  htmlType="submit"
                  onClick={() => this.props.onSubmitForm({ email, password ,recaptchaValue})}
                >
                  Log In
                </Button>
              </Form.Item>
              <Form.Item>
                <Button
                  style={{ width: "48%", marginRight: "10px" }}
                  type="danger"
                  htmlType="submit"
                >
                  <Link
                    to="/"
                    className="text-dark"
                    style={{ color: "#fff", cursor: "pointer" }}
                  >
                    Register

                  </Link>
                </Button>
                <Button
                  style={{ width: "48%", background: "#383b3c" }}
                  htmlType="submit"
                  disabled
                >
                  <a
                    href="https://anuvadak.reverieinc.com/demo"
                    className="text-dark"
                    style={{ color: "#fff", cursor: "pointer" }}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Try Demo

                  </a>
                </Button>
              </Form.Item>
            </Form>
          </div>
          {attempts > 1 && attempts != 5 && (
            <p className="text-center" style={{ color: "red" }}>
              Attempts remaining : {5 - attempts}
            </p>
          )}
          <div>
            <p style={{ display: "flex", justifyContent: "space-between" }} className="font-semibold text-muted text-center">
              <Link to="/privacy-policy" className="font-bold text-dark">
                Privacy Policy
              </Link>
              {/* <br /> */}
              <Link
                to="/forgotPassword"
                className="text-dark"
                style={{ color: "#0f3dd4", cursor: "pointer" }}
              >
                Forgot Password
              </Link>
            </p>
          </div>
        </div>
      </Content>
    );
  }
}

export default SignInForm;
