// eslint-disable-next-line no-undef
const configUI = {
  prodRun: true,
  serviceType: ["translation", "proofreading", "editing"],
  finance_admin_key: "ca1e286c-fb27-4713-bd2c-c17c0527f41e",
  kyc_doc: [
    "aadhaar",
    "panCard",
    "roc",
    "gst",
    "bankDetails",
    "contract",
    "cancelledCheck",
  ],
  documentStatus: ["pending", "accepted", "rejected", "signed"],
  servicePrice: {
    translation: 1,
    proofreading: 0.5,
    editing: 0.5,
  },
  //Local
  axiosBaseUrl: "http://localhost:3001/api/",
  uiUrl: "http://localhost:3031",
  swalekhUrl: "https://avtstagecdn.blob.core.windows.net/static/swalekh.js",
  keyboard: "http://localhost:3031/keyboard.js",
  roleObj: {
    superAdmin: "Super Admin",
    deliveryManager: " Delivery Manager",
    collaborator: "Collaborator",
    organisationAdmin: "Organisation",
    freeOrgAdmin: "Organisation(Free)",
    translationAdmin: "Translation Admin",
    lokSabhaAdmin: "Loksabha Admin",
    rajyaSabhaAdmin: "Rajyasabha Admin",
    lokSabhaTranslator: "Loksabha Translator",
    rajyaSabhaTranslator: "Rajyasabha Translator"
  },
  languageMap: {
    "en": "english",
    "hi": "hindi",
    "od": "odia",
    "pa": "punjabi",
    "gu": "gujarati",
    "mr": "marathi",
    "te": "telugu",
    "ta": "tamil",
    "as": "assamese",
    "bn": "bengali",
    "ml": "malayalam",
    "kn": "kannada",
    "ur": "urdu",
    "mai": "maithili",
    "bh": "maithili",
    "sa": "sanskrit",
    "kok": "konkani",
    "ks": "kashmiri",
    "kas-IN": "kashmiri(arabic)",
    "mni": "manipuri",
    "af": "afrikaans",
    "sq": "albanian",
    "am": "amharic",
    "brx": "bodo",
    "bg": "bulgarian",
    "my": "burmese",
    "km": "cambodian",
    "hr": "croatian",
    "cs": "czech",
    "da": "danish",
    "doi": "dogri",
    "nl": "dutch",
    "et": "estonian",
    "fa": "farsi",
    "fi": "finnish",
    "fr": "french",
    "de": "german",
    "el": "greek",
    "hu": "hungarian",
    "is": "icelandic",
    "id": "indonesia",
    "it": "italian",
    "ku": "kurdish",
    "lo": "lao",
    "ln": "lingala",
    "mg": "malagasy",
    "ms": "malay",
    "mt": "maltese",
    "mn": "mongolian",
    "ne": "nepali",
    "no": "norwegian",
    "ps": "pashto",
    "pl": "polish",
    "pt": "portuguese",
    "ro": "romanian",
    "ru": "russian",
    "sat": "santali",
    "sr": "serbian",
    "sd": "sindhi",
    "si": "sinhalese",
    "sk": "slovak",
    "sl": "slovenian",
    "so": "somali",
    "ckb": "sorani",
    "es": "spanish",
    "sw": "swahili",
    "sv": "swedish",
    "syl": "sylheti",
    "tg": "tajik",
    "th": "thai",
    "tr": "turkish",
    "tw": "twi",
    "uk": "ukrainian",
    "uz": "uzbek",
    "vi": "vietnamese",
    "zu": "zulu",
    "ar": "arabic",
    "az": "azeri",
    "dv": "dhivehi",
    "iw": "hebrew"
  },
  reverseLanguageMap: {
    english: "en",
    hindi: "hi",
    odia: "od",
    punjabi: "pa",
    gujarati: "gu",
    marathi: "mr",
    telugu: "te",
    tamil: "ta",
    assamese: "as",
    bengali: "bn",
    malayalam: "ml",
    kannada: "kn",
    urdu: "ur",
    maithili: "mai",
    sanskrit: "sa",
    konkani: "kok",
    kashmiri: "ks",
    "kashmiri(arabic)": "kas-IN",
    manipuri: "mni",
    afrikaans: "af",
    albanian: "sq",
    amharic: "am",
    bodo: "brx",
    bulgarian: "bg",
    burmese: "my",
    cambodian: "km",
    croatian: "hr",
    czech: "cs",
    danish: "da",
    dogri: "doi",
    dutch: "nl",
    estonian: "et",
    farsi: "fa",
    finnish: "fi",
    french: "fr",
    german: "de",
    greek: "el",
    hungarian: "hu",
    icelandic: "is",
    indonesia: "id",
    italian: "it",
    kurdish: "ku",
    lao: "lo",
    lingala: "ln",
    malagasy: "mg",
    malay: "ms",
    maltese: "mt",
    mongolian: "mn",
    nepali: "ne",
    norwegian: "no",
    pashto: "ps",
    polish: "pl",
    portuguese: "pt",
    romanian: "ro",
    russian: "ru",
    santali: "sat",
    serbian: "sr",
    sindhi: "sd",
    sinhalese: "si",
    slovak: "sk",
    slovenian: "sl",
    somali: "so",
    sorani: "ckb",
    spanish: "es",
    swahili: "sw",
    swedish: "sv",
    sylheti: "syl",
    tajik: "tg",
    thai: "th",
    turkish: "tr",
    twi: "tw",
    ukrainian: "uk",
    uzbek: "uz",
    vietnamese: "vi",
    zulu: "zu",
    arabic: "ar",
    azeri: "az",
    dhivehi: "dv",
    hebrew: "iw"
  },
  pipelineMap: {
    translation: "Translation",
    proofreading: "Proofreading",
    editing: "Editing",
  },
  pipeline: [["translation"], ["translation", "proofreading"], ["translation", "proofreading", "editing"]],
  defaultLang: [
    "hindi",
    "marathi",
    "gujarati",
    "malayalam",
    "punjabi",
    "telugu",
    "tamil",
    "bengali",
    "odia",
    "assamese",
    "kannada",
    "urdu"
  ],
  projectCreationStatus: {
    partialCreated: "Partial Created",
    processing: "Processing",
    crawling: "Crawling",
    crawlingFailed: "Crawling Failed",
    crawled: "Crawled",
    parsing: "Parsing",
    parsingFailed: "Parsing Failed",
    parsed: "Parsed",
    translationProcess: "Translation Process",
    translationCompleted: "Translation Completed",
  },
  manualTranslationStatus: {
    pendingMachineTranslation: "Pending Machine Translation Approval with DM",
    processingMachineTranslation: "Processing Machine Translation",
    completedMachineTranslation: "Completed Machine Translation",
    pendingManualTranslation: "Pending Manual Translation Approval with DM",
    processingManualTranslation: "Processing Manual Translation",
    completedManualTranslation: "Completed Manual Translation",
    rejectedManualTranslation: "Rejected Manual Translation",
    rejectedMachineTranslation: "Rejected Machine Translation",
    projectDeleted: "Project Deleted",
    signOff: "Sign Off",
    reverted: "Project  Reverted"
  },
  manualTranslationAction: {
    pendingMachineTranslation: "Pending",
    processingMachineTranslation: "Processing",
    completedMachineTranslation: "Completed",
    pendingManualTranslation: "Pending",
    processingManualTranslation: "Processing",
    completedManualTranslation: "View Batches",
    rejectedManualTranslation: "Rejected",
    rejectedMachineTranslation: "Rejected",
    projectDeleted: "Project Deleted",
    signOff: "Sign Off",
    reverted: "Project Reverted"
  },

  projectCreationStatusMap: {
    PARTIAL_CREATED: "partialCreated",
    PROCESSING: "processing",
    CRAWLING: "crawling",
    CRAWLING_FAILED: "crawlingFailed",
    CRAWLED: "crawled",
    PARSING: "parsing",
    PARSING_FAILED: "parsingFailed",
    PARSED: "parsed",
    TRANSLATION_PROCESS: "translationProcess",
    TRANSLATION_COMPLETED: "translationCompleted",
  },
  crawlingStatus: {
    INACTIVE: "INACTIVE",
		ACTIVE: "ACTIVE",
		FAILED: "FAILED",
		COMPLETED: "COMPLETED",
  },
  manualTranslationStatusMap: {
    PENDING_MACHINE_TRANSLATION: "pendingMachineTranslation",
    PROCESSING_MACHINE_TRANSLATION: "processingMachineTranslation",
    COMPLETED_MACHINE_TRANSLATION: "completedMachineTranslation",
    REJECTED_MACHINE_TRANSLATION: "rejectedMachineTranslation",
    PENDING_MANUAL_TRANSLATION: "pendingManualTranslation",
    PROCESSING_MANUAL_TRANSLATION: "processingManualTranslation",
    COMPLETED_MANUAL_TRANSLATION: "completedManualTranslation",
    REJECTED_MANUAL_TRANSLATION: "rejectedManualTranslation",
    PROJECT_DELETED: "projectDeleted",
    SIGN_OFF: "signOff",
    REVERTED: "reverted"
  },

  roleObjMap: {
    SUPER_ADMIN: "superAdmin",
    ORGANISATION_ADMIN: "organisationAdmin",
    COLLABORATOR: "collaborator",
    DELIVERY_MANAGER: "deliveryManager",
    FREE_ORG_ADMIN: "freeOrgAdmin",
    LOKSABHA_ADMIN: "lokSabhaAdmin",
    RAJYASABHA_ADMIN: "rajyaSabhaAdmin",
    LOKSABHA_TRANSLATOR: "lokSabhaTranslator",
    RAJYASABHA_TRANSLATOR: "rajyaSabhaTranslator"
  },

  scopeMap: {
    autoCrawl: "Auto Crawl",
    manual: "Manual",
  },

  translationTypeMap: {
    NO_TRANSLATIONS_AVAILABLE: "No Translations available",
    PROFESSIONAL: "Professional",
    USER: "User",
    MACHINE: "Machine"

  },

  filterMap: {
    NO_TRANSLATION_AVAILABLE: "New Content Available",
    PBK: "Prabandhak Translated",
    USER: "Self Translated",
    MACHINE: "Machine Translated"
  },

  translationTypeMap: {
    t9n: "Translated Strings",
    t13n: "Transliterated Strings"
  },

  domainVerificationStatusMap: {
    NO_VERIFICATION: 0,
    SCRIPT_VERIFIED: 1,
    PROJECT_ID_VERIFIED: 2,
    CLIENT_VERIFIED: 3
  },

  languageSupportedBySwalekh: [
    "hi",
    "od",
    "pa",
    "gu",
    "mr",
    "te",
    "ta",
    "as",
    "bn",
    "ml",
    "kn",
    "ur",
    "mai",
    "sa",
    "kok",
    "ks",
    "mni",
    "brx",
    "doi",
    "ne",
    "sd",
    "sat",
    "kas-IN"
  ],
  langToIsoMapSwalekh: {
    hindi: "hi",
    marathi: "mr",
    telugu: "te",
    tamil: "ta",
    kannada: "kn",
    malayalam: "ml",
    odia: "or",
    punjabi: "pa",
    gujarati: "gu",
    assamese: "as",
    bengali: "bn",
    urdu: "ur",
    maithili: "mai",
    sanskrit: "sa",
    konkani: "kok",
    kashmiri: "ks",
    "kashmiri(arabic)": "ks",
    manipuri: "mni",
    bodo: "brx",
    dogri: "doi",
    nepali: "ne",
    sindhi: "sd",
    santali: "sat",
  },

  prabandhakIpAddress: "40.64.33.149",
  fileProcessingStatus: {
    NEW_URL: "NEW_URL",
    PBK_SENT: "PBK_SENT",
    NMT_TRANSLATED: "NMT_TRANSLATED",
    PBK_TRANSLATED: "PBK_TRANSLATED",
    PBK_EXPORTED: "PBK_EXPORTED",
    FAILED: "FAILED"
  },

  apiTranslationStatus: {
    "NONE": "none",
    "SYNCING": "syncing",
    "SYNCED": "synced",
    "APPLIED_NMT": "applied_nmt",
    "SENT_TO_PBK": "sent_to_pbk",
    "PBK_TRANSLATED": "pbk_translated"
  },

  categoryNameMapping: {
    "List of Business": "LOB",
    "Bulletin Part-I": "BUL1",
    "Bulletin Part-II": "BUL2",
    "Questions List": "QL",
    "Synopsis": "SYN",
    "Paper to be Laid": "PTBL",
  },

  categoryMapping: {
    ls: {
      "List of Business": {
        "Normal": "N",
        "Revised List of Business": "R",
        "Supplementary List of Business-1": "S1",
        "Supplementary List of Business-2": "S2",
        "Supplementary List of Business-3": "S3",
      },
      "Bulletin Part-I": {},
      "Bulletin Part-II": {},
      "Questions List": {},
      "Synopsis": {},
      "Paper to be Laid": {},
    },
    rs: {
      "List of Business": {
        "Normal": "N",
        "Revised List of Business": "R",
        "Supplementary List of Business-1": "S1",
        "Supplementary List of Business-2": "S2",
        "Supplementary List of Business-3": "S3",
      },
      "Bulletin Part-I": {},
      "Bulletin Part-II": {},
      "Questions List": {
        "Starred Questions": "Starred",
        "Unstarred Questions": "UnStarred",
      },
      "Synopsis": {
        "Normal": "N",
        "Supplementary Synopsis-1": "S1",
        "Supplementary Synopsis-2": "S2",
      },
      "Paper to be Laid": {},
    },
  },

  contentTypeOptions: [
    {
      value: "cms",
      label: "Strapi (CMS)"
    },
    {
      value: "api",
      label: "Dynamic (API)"
    }
  ],

  apiDomainOptions: [
    {
      value: "ls",
      label: "LokSabha",
    },
    {
      value: "rs",
      label: "RajyaSabha"
    },
  ],

  cmsDomainOptions: [
    {
      value: "ls_pp_test",
      label: "LokSabha People's Portal (Test)",
    },
    {
      value: "ls_mp_test",
      label: "LokSabha Member's Portal (Test)",
    },
    {
      value: "rs_pp_test",
      label: "RajyaSabha People's Portal (Test)",
    },
    {
      value: "rs_mp_test",
      label: "RajyaSabha Member's Portal (Test)",
    },
    {
      value: "poi_test",
      label: "Parliament Portal (Test)",
    },
    {
      value: "ls_pp_stage",
      label: "LokSabha People's Portal (Stage)",
    },
    {
      value: "ls_mp_stage",
      label: "LokSabha Member's Portal (Stage)",
    },
    {
      value: "rs_pp_stage",
      label: "RajyaSabha People's Portal (Stage)",
    },
    {
      value: "rs_mp_stage",
      label: "RajyaSabha Member's Portal (Stage)",
    },
    {
      value: "poi_stage",
      label: "Parliament Portal (Stage)",
    },
    {
      value: "ls_pp_prod",
      label: "LokSabha People's Portal (Prod)",
    },
    {
      value: "ls_mp_prod",
      label: "LokSabha Member's Portal (Prod)",
    },
    {
      value: "rs_pp_prod",
      label: "RajyaSabha People's Portal (Prod)",
    },
    {
      value: "rs_mp_prod",
      label: "RajyaSabha Member's Portal (Prod)",
    },
    {
      value: "poi_prod",
      label: "Parliament Portal (Prod)",
    },
    {
      value: "ls_pp_local",
      label: "LokSabha People's Portal (Local)",
    },
    {
      value: "ls_mp_local",
      label: "LokSabha Member's Portal (Local)",
    },
    {
      value: "rs_pp_local",
      label: "RajyaSabha People's Portal (Local)",
    },
    {
      value: "rs_mp_local",
      label: "RajyaSabha Member's Portal (Local)",
    },
    {
      value: "poi_prod_local",
      label: "Parliament Portal (Local)",
    },
  ],

  apiTypeOption: [
    {
      value: "singleType",
      label: "SingleType",
    },
    {
      value: "collectionType",
      label: "collectionType",
    },
  ],
  environmentMap: {
    prod: "Prod Environment",
    staging: "Stagging Environment"
  },
  statusMap: {
    projectCreated: "Project Created",
    processing: "Processing",
    completed: "Completed"
  },
  apiTranslationStatus: {
    created: "CREATED",
    syncing: "SYNCING",
    synced: "SYNCED",
    applied_nmt: "APPLIED NMT",
    sent_to_pbk: "SENT TO PBK",
    pbk_translated: "PBK TRANSLATED",
  },
  apiTranslationStatusColorCode: {
    created: "#108ee9",
    syncing: "#2b6e99",
    synced: "#2b6e99",
    applied_nmt: "#87d068",
    sent_to_pbk: "#2b6e99",
    pbk_translated: "#87d068",
  },
  suggestionStatusConfig: {
    ACCEPTED: "accepted",
    REJECTED: "rejected",
    NEW: "new",
    INPROCESS: "inprocess",
  },
  contentTypeMap: [
    {
      "static": "Static Translation(CMS)"
    },
    {
      "dynamic": "Dynamic/JSON Translation"
    }
  ],
};
export default configUI;
