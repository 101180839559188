import axios from "./index";

//route to fetch page top stats
export async function fetchStat(params){
    return axios.post("/dashboard/fetchStat",params)
}

//route to fetch page month wise stats
export async function fetchMonthWiseStat(params){
    return axios.post("dashboard/monthWiseStat", params)
}

//route to fetch page domain wise stats
export async function fetchDomainWiseStat(params){
    return axios.post("dashboard/domainWiseStat", params)
}

//route to download domain wise data
export async function downloadReport(params){
    return axios.post("dashboard/downloadDomainWiseData", params)
}

//route to fetch URL wise Stats data
export async function fetchUrlWiseStats(params){
    return axios.post("dashboard/urlWiseStat", params)
}

//route to fetch notifications
export async function fetchNotifications(params){
    return axios.post("dashboard/fetchNotifications", params)

}

//route to update notifications
export async function updateNotifications(params){
    return axios.post("dashboard/updateNotifications", params)

}
