import { io } from "socket.io-client";
import ReducerFactory from "../../utils/reducerFactory";
import appConfig from "../../appConfig"


const reducerName = "websocket";
const initialState = {
  socket: null,
  roomJoined: false
};

const reducerFactory = new ReducerFactory(reducerName, initialState);

// pass the socket state as params to this action this would check for
// if socket already exists then use the old one or else create a new one
reducerFactory.addAction(
  "INIT_WEBSOCKET",
  `initWebsocket`,
  async (socket) => { 
    if (!socket) {
      socket = io(
        appConfig.prodRun
          ? `${window.location.origin}`
          : "http://localhost:3001"
      );
    }

    return socket;
  },
  (state, action) => {
    if (!state.socket) {
      const newState = Object.assign(state);
      newState.socket = action.data;
      return newState;
    }
    return state;
  }
);

// this action disconnects the socket and reset the socket state to null
reducerFactory.addAction(
  "DISCONNECT_WEBSOCKET",
  `disconnectWebsocket`,
  async (socket) => {
    if (socket) {
      socket.disconnect();
    }
    return true;
  },
  (state, action) => {
    const newState = Object.assign(state);
    newState.socket = null;
    return newState;
  }
);

reducerFactory.addAction(
  "JOIN_ROOM",
  "joinRoom",
  ({socket, roomId})=>{
    socket.emit("call", "notification.joinRoom", { roomId });
    return {};
  },
  (state,action) => {
    const newState = Object.assign(state);
    newState.roomJoined = true;
    return newState;
  }
)
export default reducerFactory;
